(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name messages.controller:MessagesCtrl
   *
   * @description
   *
   *
   * messages = 1
   * notes = 0
   *
   */
  angular
    .module('messages')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl($localStorage, $scope, Aside, Messages, $location, Events, RCRUsers, $state) {
    var vm = this;
	
	//messageType: messages = 1 , notes = 0

    if(!RCRUsers.isAuthenticated()){$location.path('/');}


    vm.showAside = Aside.showAside;

    vm.expandAside = function(){
      Aside.expandAside();
    }
    vm.shrinkAside = function(){
      Aside.shrinkAside();
    }

    vm.reqId = Aside.reqId;
    $scope.$watch(function(){
      return Aside.reqId;
    }, function(value){
		vm.reqId = Aside.reqId;
		if(value){
		  Messages.getMessages({"rcrId": value, "userId": $localStorage.loggedInUser.id}).$promise.then(function (results) {
			//Messages.markAsRead({userId: $localStorage.loggedInUser.id, rcrId: value});
			vm.messages = results;
			vm.rcrId = value;
			
			Messages.updateAll({where:{"rcrParentId": value, "dateRead": null, "fromUserId": {"neq": $localStorage.loggedInUser.id}}}, {"dateRead":moment().unix()}, function(err,info){
			});
		  });
		}
		
    });
	
	$scope.$watch(function(){
		return $location.path();
	}, function(val){
		vm.closeAside();
	});

    vm.expanded = Aside.expanded;

    $scope.$watch(function(){
      return Aside.expanded;
    }, function(value){
      vm.expanded = value;
    });

    vm.closeAside = function(){
		
		Aside.closeAside();
    };

    vm.sendMessage = function (rcrId) {
      vm.send(rcrId, 1);
    };

    vm.sendNote = function (rcrId) {
      vm.message = vm.note;
      vm.send(rcrId, 0);
    };

    vm.send = function (rcrId, messageType) {
        var postData = {
          "rcrParentId": parseInt(rcrId, 10),
          "fromUserId": $localStorage.loggedInUser.id,
          "toUserId": 0,
          "message": vm.message,
          "messageType": messageType,
          "dateSent": Math.round(Date.now() / 1000)
        };
      Messages.create(postData, function (response ) {
        Events.create({
			"userId": response["fromUserId"],
			"rcrParentId": response["rcrParentId"],
			"eventType":"add message/note", 
			"eventData":response
		});
		//console.log(response);
		Messages.getMessages({"rcrId": response.rcrParentId, "userId": $localStorage.loggedInUser.id}).$promise.then(function (results) {
		  vm.messages = results;
			if (messageType == 1){
			  Messages.sendEmail({"rcrParentId": response.rcrParentId, "user": $localStorage.loggedInUser}).$promise.then(function (results) {
			  });
			}

		});
		vm.message = '';
      });
    }
  }
}());
